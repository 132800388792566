import {keyBy, pick} from 'lodash'
import {toWkt} from '@helpers/openlayers/features'

import {makeSetters} from '@helpers/vuex/mutations'
import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'
import {calculateToOxidform} from '@frs/components/basic-fertilization/oxidform-calculation'

const defaults = () => ({
  data: {
    common: {
      entireField: true,
      targetValue: 'humus',
      selectedMaps: []
    },
    upload: {
      mappingDate: null,
      method: 'standard',
      blockSize: 10,
      pixelSize: 2,
      sensorData: null, // geoJson
      selectedAttributes: []
    },
    calibration: {
      availableOrders: {},
      selectedOrders: []
    },
    algorithmTypes: ['vdlufa', 'stepless'],
    requiredNutrient: {
      algorithmType: 'vdlufa',
      soilGroupMapId: null,
      clayMapId: null,
      humusMapId: null,
      phMapId: null,
      limingInterval: null,
      soilTesting: null,
      showMap: {
        soilGroup: false,
        humusContent: false,
        pH: false,
        clayContent: false
      }
    },
    application: {
      fileFormat: 'IsoXML',
      requiredNutrientMapId: null,
      alignInDrivingDirection: false,
      spreaderWorkingWidth: 12,
      rasterLength: 40, // specified by project (WAA-878)
      lane: null,
      caOMap: null,
      classifyGridToMaximumUCUs: false
    },
    limeApplication: {
      caoApplicationMapId: null,
      limeApplicationMap: null,
      fertilizerId: null,
      useExistingFertilizer: true,
      customFertilizer: {
        name: '',
        caValue: 0,
        mgValue: 0,
        useOxidValues: false
      },
      adjustLowerLimit: false,
      applicationRate: {
        minAmount: 0,
        maxAmount: 0,
        meanValue: 0,
        totalRequirement: 0
      }
    }
  },
  ui: {
    application: {
      step: 'start' // 'start', 'calculating', 'calculatedSuccess', 'classificationLimitExceeded'
    },
    limeApplication: {
      limeApplicationStep: 'start' // 'start', 'calculating', 'calculated'
    }
  }
})

const state = defaults()

export default {
  namespaced: true,
  state,
  getters: {
    soilMaps (state, getters, rootState, rootGetters) {
      const soilMapLookup = rootState.fieldRecordSystem.mapManagement.data.soilMap.mapLookup
      const soilMapIdsByFieldId = rootState.fieldRecordSystem.mapManagement.data.soilMap.mapIdsByFieldId
      const fieldId = rootGetters['fieldRecordSystem/fieldId']

      if (!soilMapIdsByFieldId[fieldId]) {
        return []
      }

      const soilMapIds = soilMapIdsByFieldId[fieldId]
      const maps = soilMapIds.map(id => {
        return soilMapLookup[id]
      })

      return maps
    },
    requiredNutrientMaps (state, getters) {
      if (!getters.soilMaps.length) return []

      return getters.soilMaps.filter(x => x.category === 'caORequiredNutrient')
    },
    caOApplicationMaps (state, getters) {
      if (!getters.soilMaps.length) return []

      return getters.soilMaps.filter(x => x.category === 'caOApplicationMap')
    }
  },
  actions: {
    createRequiredNutrientMap ({state, rootGetters}) {
      const harvestYear = rootGetters['fieldRecordSystem/harvestYear']
      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
      const {algorithmType, soilGroupMapId, clayMapId, humusMapId, phMapId, soilTesting, limingInterval} = {...state.data.requiredNutrient}
      const data = {
        soilGroupMapId,
        humusMapId,
        phMapId,
        clayMapId,
        soilTesting,
        limingInterval,
        fieldId
      }
      return smarterPost(`/api/v2/phbb/cao/required-nutrient-map/create/${orgUnitId}/${algorithmType}/${harvestYear}`, data, {
        id: 'phbb.create.caoRequiredNutrientMap'
      })
    },
    createCaOApplicationMap ({state, commit, rootGetters}) {
      const harvestYear = rootGetters['fieldRecordSystem/harvestYear']
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
      const fieldId = rootGetters['fieldRecordSystem/fieldId']

      const {fileFormat, requiredNutrientMapId, alignInDrivingDirection, spreaderWorkingWidth, rasterLength, lane, classifyGridToMaximumUCUs} = {...state.data.application}
      const data = {
        fileFormat,
        requiredNutrientMapId,
        alignInDrivingDirection,
        spreaderWorkingWidth,
        rasterLength,
        fieldId,
        lane,
        classifyGridToMaximumUCUs
      }

      if (state.data.application.caOMap) {
        data.mapId = state.data.application.caOMap.id
      }

      return smarterPost(`/api/v2/phbb/cao/create-application/${orgUnitId}/${harvestYear}`, data, {
        id: 'phbb.create.caoApplication'
      })
      .then(map => {
        commit('setCaOMap', map)
      })
    },
    saveCaOMapWithoutRecalculation ({state, rootGetters}) {
      const harvestYear = rootGetters['fieldRecordSystem/harvestYear']
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
      const {borderGeometry, category, generatedAt, id, metaData, name, origin, zones} = {...state.data.application.caOMap}
      const data = {
        borderGeometry,
        category,
        generatedAt,
        id,
        metaData,
        name,
        origin,
        zones
      }

      return smarterPost(`/api/v2/phbb/cao/create-application/save/${orgUnitId}/${harvestYear}`, data, {
        id: 'phbb.create.caoApplication.save'
      })
    },
    createLimeApplicationMap ({state, commit, dispatch, rootGetters}, isRecalculation = false) {
      const harvestYear = rootGetters['fieldRecordSystem/harvestYear']
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
      const fieldId = rootGetters['fieldRecordSystem/fieldId']

      const products = rootGetters['masterData/products']

      const {caoApplicationMapId, fertilizerId, useExistingFertilizer} = {...state.data.limeApplication}

      const fertilizer = products[fertilizerId]

      let caO, mgO

      if (useExistingFertilizer) {
        caO = fertilizer.lime
        mgO = calculateToOxidform('magnesium', fertilizer.magnesium)
      } else {
        const {caValue, mgValue, useOxidValues} = state.data.limeApplication.customFertilizer
        caO = useOxidValues ? caValue * 0.4004 : caValue
        mgO = useOxidValues ? mgValue * 0.478 : mgValue
      }

      let data = {
        caoApplicationMapId,
        fieldId,
        productId: fertilizerId,
        CaOValue: caO.toFixed(2),
        MgOValue: mgO.toFixed(2)
      }

      if (isRecalculation) {
        const {minAmount, maxAmount, totalRequirement} = state.data.limeApplication.applicationRate

        data.isRecalculation = true
        data.fertilizerMinAmount = minAmount
        data.fertilizerMaxAmount = maxAmount
        data.fertilizerTotalRequirement = totalRequirement
        data.adjustLowerLimit = state.data.limeApplication.adjustLowerLimit
      }

      return smarterPost(`/api/v2/phbb/cao/create-lime-application/${orgUnitId}/${harvestYear}`, data, {
        id: 'phbb.create.caoApplication'
      })
      .then(async result => {
        commit('setLimeApplicationMap', result)
        commit('setApplicationRate', result.metaData)
        commit('dataLoading/invalidate', 'applicationMapInfos.forField', {root: true})
        await dispatch('fieldRecordSystem/mapManagement/loadAvailableApplicationMapsForField', fieldId, {root: true})
      })
    },
    saveCustomFertilizer ({state, commit, rootGetters}) {
      const harvestYear = rootGetters['fieldRecordSystem/harvestYear']
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

      const {name, caValue, mgValue, useOxidValues} = state.data.limeApplication.customFertilizer

      const product = {
        harvestYear,
        orgUnitId,
        type: 'fertilizer',
        isOrganic: false,
        isLiquid: false,
        magnesium: useOxidValues ? mgValue * 0.478 : mgValue, // factor from MgCO3 to MgO is 0.478
        lime: useOxidValues ? caValue * 0.4004 : caValue, // factor from CaCO3 to CaO is 0.4004
        name,
        source: 'phbb'
      }
      return smarterPost('/api/v2/master-data/products', product, {
        id: 'phbb.create.product'
      })
      .then(product => {
        commit('setFertilizerId', product.id)
      })
    },
    uploadAndInterpolateSensorData ({state, rootGetters}) {
      const harvestYear = rootGetters['fieldRecordSystem/harvestYear']
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      const {entireField} = {...state.data.common}
      const {mappingDate, method, blockSize, pixelSize, sensorData, selectedAttributes} = {...state.data.upload}
      const data = {
        entireField,
        mappingDate,
        method,
        blockSize,
        pixelSize,
        fieldId,
        sensorData,
        selectedAttributes
      }
      return smarterPost(`/api/v2/phbb/sensor-data/interpolate/${orgUnitId}/${harvestYear}`, data, {
        id: 'phbb.upload.sensorData'
      })
    },
    calibrateSensorData ({state, rootGetters}) {
      const harvestYear = rootGetters['fieldRecordSystem/harvestYear']
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
      const fieldId = rootGetters['fieldRecordSystem/fieldId']

      const {entireField, targetValue, selectedMaps} = {...state.data.common}
      const {selectedOrders, availableOrders} = {...state.data.calibration}

      const dataFields = (value) => {
        switch (value) {
        case 'ph':
          return ['ph']
        case 'humus':
          return ['humus']
        case 'soilTexture':
          return ['sand', 'silt', 'clay']
        }
      }

      const samplingPoints = selectedOrders.reduce((acc, id) => {
        const samplingPointsPerOrder = availableOrders[id].features.map(feature => {
          const dataProps = pick(feature.properties, dataFields(targetValue))
          const values = Object.keys(dataProps).map(key => ({name: key, value: dataProps[key]}))

          return {
            id: feature.properties.sampleId,
            probingDate: availableOrders[id].created,
            geometry: toWkt(feature),
            values
          }
        })
        return acc.concat(samplingPointsPerOrder)
      }, [])

      const data = {
        entireField,
        targetValue,
        selectedMaps,
        samplingPoints
      }
      return smarterPost(`/api/v2/phbb/sensor-data/calibrate/${orgUnitId}/${fieldId}/${harvestYear}`, data, {
        id: 'phbb.calibration.sensorData'
      })
    },
    loadCompletedFullOrders ({commit, rootGetters}) {
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']
      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      const harvestYear = rootGetters['fieldRecordSystem/harvestYear']

      // get completed orders only
      return smarterGet('/api/v2/phbb/orders/{fieldId}/{harvestYear}', {
        id: 'phbb.calibration.orders',
        expiry: 0,
        inputs: {
          fieldId: () => fieldId,
          orgUnitId: () => orgUnitId,
          harvestYear: () => harvestYear
        },
        onResult (orders) {
          const lookup = keyBy(orders, 'id')
          commit('setAvailableOrders', lookup)
        }
      })
    }
  },
  mutations: {
    ...makeSetters([
      'data.common.entireField',
      'data.common.targetValue',
      'data.common.selectedMaps',

      'data.upload.mappingDate',
      'data.upload.method',
      'data.upload.epsg',
      'data.upload.blockSize',
      'data.upload.pixelSize',
      'data.upload.sensorData',
      'data.upload.selectedAttributes',

      'data.calibration.availableOrders',
      'data.calibration.selectedOrders',

      'data.requiredNutrient.algorithmType',
      'data.requiredNutrient.soilGroupMapId',
      'data.requiredNutrient.clayMapId',
      'data.requiredNutrient.humusMapId',
      'data.requiredNutrient.phMapId',
      'data.requiredNutrient.soilTesting',
      'data.requiredNutrient.showMap',
      'data.requiredNutrient.limingInterval',

      'data.application.fileFormat',
      'data.application.requiredNutrientMapId',
      'data.application.alignInDrivingDirection',
      'data.application.spreaderWorkingWidth',
      'data.application.lane',
      'data.application.classifyGridToMaximumUCUs',

      'data.limeApplication.limeApplicationMap',
      'data.limeApplication.caoApplicationMapId',
      'data.limeApplication.fertilizerId',
      'data.limeApplication.adjustLowerLimit',
      'data.limeApplication.applicationRate.minAmount',
      'data.limeApplication.applicationRate.maxAmount',
      'data.limeApplication.applicationRate.meanValue',
      'data.limeApplication.applicationRate.totalRequirement',
      'data.limeApplication.useExistingFertilizer',
      'data.limeApplication.customFertilizer.name',
      'data.limeApplication.customFertilizer.caValue',
      'data.limeApplication.customFertilizer.mgValue',
      'data.limeApplication.customFertilizer.useOxidValues',

      'ui.application.step',
      'ui.limeApplication.limeApplicationStep'
    ]),
    setDefaultState (state) {
      Object.assign(state, defaults())
    },
    setCaOMap (state, map) {
      state.data.application.caOMap = map
    },
    setApplicationRate (state, applicationRate) {
      state.data.limeApplication.applicationRate.minAmount = applicationRate.minAmount
      state.data.limeApplication.applicationRate.maxAmount = applicationRate.maxAmount
      state.data.limeApplication.applicationRate.meanValue = applicationRate.meanValue
      state.data.limeApplication.applicationRate.totalRequirement = applicationRate.totalRequirement
    }
  }
}
